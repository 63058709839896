import * as signalR from '@microsoft/signalr';
import { __APIURLVIRTUALEXPO__ } from '../consts/consts';
import Storage from './Storage';
import { store } from '../index';
import { notificationReceived, liveFeedMessageReceived, receiveMessageFromServer, enterStand, leaveStand, certificateCreated } from '../redux/actions';
import { dispatch } from 'redux';
import messageSound from '../assets/messageSound.mp3';
import enterStandSound from '../assets/enterStand.mp3';
import notificationSound from '../assets/notificationSound.mp3';
import Utils from './Utils';

class SignalrSocket {

    constructor() {
        this.signalRSocket = null;
        this.pageTitle = document.title;
        this.unfocusMessagesReceived = 0;
    }

    getSocket() {
        return this.signalRSocket;
    }

    create() {
        this.signalRSocket = new signalR.HubConnectionBuilder()
            .withUrl(__APIURLVIRTUALEXPO__ + 'chat', { accessTokenFactory: () => Storage.getJwtToken() })
            .withAutomaticReconnect([0, 2000, 5000, 5000, 5000, 5000, 10000, 10000, 10000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, 30000, null])
            .configureLogging(signalR.LogLevel.Information)
            .withHubProtocol(new signalR.JsonHubProtocol())
            .build()
    }

    chatHandler = (fromIdPerson, toPersonId, message, msgId) => {

        if (fromIdPerson.toString() != Storage.getIdPerson().toString()) {
            let audio = new Audio(messageSound);
            audio.play();
            // if (document.hasFocus() == false){
            //     document.title = ++this.unfocusMessagesReceived + " mensaje(s) nuevo";
            // }
            // else{
            //     document.title = this.pageTitle;
            //     this.unfocusMessagesReceived =0;
            // }

        }

        let messageFromServer = {
            fromPersonId: fromIdPerson,
            toPersonId: toPersonId,
            message: message,
            messageId: msgId
        }
        store.dispatch(receiveMessageFromServer(messageFromServer));
    }
    standActionHandler = (fromIdPerson, personIdExhibitor, action) => {


        if (personIdExhibitor != Storage.getIdPerson()) {
            return;//Me llego el msj de otro expositor
        }
        let standEventInfo = {
            fromPersonId: fromIdPerson,
            fromPersonExhibitorId: personIdExhibitor
        }
        if (action == "enter") {
            let audio = new Audio(enterStandSound);
            audio.play();
            store.dispatch(enterStand(standEventInfo));
        }
        else {
            store.dispatch(leaveStand(standEventInfo));
        }

    }
    unsanitazed(str) {
        var ret = str.replace(/&gt;/g, '>');
        ret = ret.replace(/&lt;/g, '<');
        ret = ret.replace(/&quot;/g, '"');
        ret = ret.replace(/&apos;/g, "'");
        ret = ret.replace(/&amp;/g, '&');
        return ret;
    };
    notificationHandler = (notification) => {

        var newNotification = { ...notification, content: this.unsanitazed(notification.content), showToast: true }
        store.dispatch(notificationReceived(newNotification));
        let audio = new Audio(notificationSound);
        audio.play();
    }


    liveFeedHandler = (liveFeedMessage) => {

        store.dispatch(liveFeedMessageReceived(liveFeedMessage));
    }

    heartbeatHandler = () => {
        try {

            Utils.logOnConsole('Se recibió un heartbeat<=...');
            // verifico que el evento siga abierto
            if (Utils.isEventFinished() && Storage.getOverdueAccess() == false) {
                Utils.logOnConsole('Se desloguea porque no esta abierto el evento y no tiene permisos...');
                Utils.logOut();
            }
            let idRoom = Storage.getLiveStreamRoomId();
            let showingMiniVideoPlayer = false;
            if (Storage.getMiniPlayerSelectedRoomId() != null) {
                showingMiniVideoPlayer = true;
            }
            if (idRoom == null) {
                idRoom = Storage.getMiniPlayerSelectedRoomId();
            }
            this.signalRSocket.invoke('heartbeat', window.location.href + "||" + Number(idRoom) + "||" + showingMiniVideoPlayer);
            Utils.logOnConsole('Se envió un heartbeat=>...');
        }
        catch (ex) {
            Utils.sendErrorToServer("Ocurrio un error enviando un HEARTBEAT", "SignalrSocket", ex);
        }
    }


    certificateCreatedHandler = (certificateUrl) => {

        store.dispatch(certificateCreated(certificateUrl));
    }

    updateCalendar() {
        Utils.logOnConsole("Updating calendar")
    }

    socketClosed() {
        console.log('socket cerrado!');
    }

    start() {
        this.signalRSocket.start().then(() => {

            console.log('socket abierto!');
            // var heartBeat = this.signalRSocket.connection.heartBeatHub;
            // heartBeat.server.send('Heart beat listening');
        })
            .catch(err => {
                console.error(err, 'red');
                let error = "Obj err: " + JSON.stringify(err);
                error += "Hub Url: " + this.signalRSocket.connection.baseUrl;
                Utils.sendErrorToServer("Ocurrio un error abriendo en el start del socket", "SignalrSocket", error);
                setTimeout(function () {
                    Storage.clearAll();//puede ser un tema con las cookies
                    window.location.href = "/";
                }, 4000);
                throw err;
            });
        this.signalRSocket.on('chat', this.chatHandler);
        this.signalRSocket.on('standAction', this.standActionHandler);
        this.signalRSocket.on('notification', this.notificationHandler);
        this.signalRSocket.on('liveFeed', this.liveFeedHandler);
        this.signalRSocket.on('certificateCreated', this.certificateCreatedHandler);
        this.signalRSocket.on('heartbeat', this.heartbeatHandler);
        this.signalRSocket.on('calendarUpdate', this.updateCalendar);
        this.signalRSocket.onclose(this.socketClosed());
        var me = this;
    }



}

export default SignalrSocket