export const increment = () => {
    return {
        type: 'INCREMENT'
    }
}

export const selectParticipant = (payload) => {
    return {
        type: 'SELECT_PARTICIPANT',
        payload: payload
    }
}



export const markAsReadAllMessagesFrom = (payload) => {
    return {
        type: 'MARK_AS_READ_ALL_MESSAGES_FROM',
        payload: payload
    }
}

export const enterStand = (payload) => {
    return {
        type: 'ENTER_STAND',
        payload: payload
    }
}

export const leaveStand = (payload) => {
    return {
        type: 'LEAVE_STAND',
        payload: payload
    }
}

export const receiveMessageFromServer = (payload) => {
    return {
        type: 'RECEIVE_MESSAGE_FROM_SERVER',
        payload: payload
    }
}


export const selectExhibitor = (payload) => {
    return {
        type: 'GOTOEXHIBITOR',
        payload: payload
    }
}

export const notificationReceived = (payload) => {
    return {
        type: 'NOTIFICATION_RECEIVED',
        payload: payload
    }
}

export const notificationReaded = (payload) => {
    return {
        type: 'NOTIFICATION_READED',
        payload: payload
    }
}

export const clearNotificationCounter = (payload) => {
    return {
        type: 'CLEAR_NOTIFICATION_COUNTER',
        payload: payload
    }
}


export const liveFeedMessageReceived = (payload) => {
    return {
        type: 'LIVEFEED_MESSAGE_RECEIVED',
        payload: payload
    }
}
export const liveFeedClearMessages = (payload) => {
    return {
        type: 'LIVEFEED_CLEAR',
        payload: payload
    }
}

export const miniVideoPlayerShow = (payload) => {
    return {
        type: 'MINI_VIDEO_PLAYER_SHOW',
        payload: payload
    }
}
export const miniVideoPlayerHide = (payload) => {
    return {
        type: 'MINI_VIDEO_PLAYER_HIDE',
        payload: payload
    }
}

export const certificateCreated = (payload) => {
    return {
        type: 'CERTIFICATE_CREATED',
        payload: payload
    }
}

export const updateGameResults = (payload) => {
    return {
        type: 'UPDATE_GAME_RESULTS',
        payload: payload
    }
}

export const setGameResults = (payload) => {
    return {
        type: 'SET_GAME_RESULTS',
        payload: payload
    }
}

