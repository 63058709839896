
const initialState = {
    show: false


  }
  
const miniVideoPlayerReducer =  (state = initialState,action) => {
    switch(action.type){
        case 'MINI_VIDEO_PLAYER_SHOW':{
            return { 
                ...state,
                show: true
            }
          
        }
        case 'MINI_VIDEO_PLAYER_HIDE':{
            return { 
                ...state,
                show: false
            }
          
        }
     
       
        default:
            return state;
    }
}

export default miniVideoPlayerReducer;