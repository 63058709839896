import React,{ Suspense }  from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {createStore} from 'redux';
import allReducers from './redux/reducers';
import { Provider } from 'react-redux';
import './assets/main.scss';
import { configureStore } from './redux/store';
import 'react-image-lightbox/style.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import SignalRSocket from './components/SignalrSocket';

// const store = createStore(allReducers,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export const socket = new SignalRSocket();
export const store = configureStore(undefined,socket);

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter  >
       <Suspense fallback={<div className="loading" />}>
        <App  />
       </Suspense>
    </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
