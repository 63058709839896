import loggedReducer from './isLogged';
import conversationReducer from './conversation';
import {combineReducers} from 'redux';
import searchBarReducer from './seachBar';
import menuReducer from './menu';
import settingsReducer  from './settings';
import chatReducer from '../chat/reducer';
import liveFeed from './liveFeed';
import notificationCenterReducer from './notificationCenter';
import certificateReducer from './certificate';
import miniVideoPlayerReducer from './miniVideoPlayer';

const allReducers = combineReducers ({
    menu : menuReducer,
    settings: settingsReducer,
    conversation : conversationReducer,
    searchBar: searchBarReducer,
    chatApp: chatReducer,
    liveFeed: liveFeed,
    notificationCenter: notificationCenterReducer,
    certificate:certificateReducer,
    miniVideoPlayer:miniVideoPlayerReducer
})

export default allReducers;